<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar">
          <Sidebar />
        </div>
        <div class="column">
          <div>
            <div class="is-flex is-justify-content-space-between block">
              <div>
                <h1 class="title">FTX Fee Calculator</h1>
                <h2 class="subtitle">
                  Calculate your fees
                </h2>
              </div>
            </div>

            <div class="block">
              <label class="label">Position Size</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1">
                  <input
                      v-model.number="position_size"
                      class="input"
                      type="text"
                      placeholder="Position size"
                  />
                  <p class="help">In cryptocurrencies like USDT, BTC, etc. or fiat currencies</p>
                </div>
              </div>

              <label class="label">Order Type</label>
              <div class="field has-addons">
                <div class="control">
                  <label class="radio">
                    <input v-model="order_type" value="maker" type="radio" name="order_type">
                    Maker
                  </label>
                  <label class="radio">
                    <input v-model="order_type" value="taker" type="radio" name="order_type">
                    Taker
                  </label>
                  <p class="help">Maker = limit order, Taker = market order</p>
                </div>
              </div>

              <label class="label">30 Day Trade Volume (USD)</label>
              <div class="field has-addons">
                <div class="control">
                  <label class="radio">
                    <input v-model.number="trade_volume" value="1" type="radio" name="trade_volume">
                    0 - 2,000,000
                  </label>
                  <label class="radio">
                    <input v-model.number="trade_volume" value="2" type="radio" name="trade_volume">
                    > 2,000,000
                  </label>
                  <label class="radio">
                    <input v-model.number="trade_volume" value="3" type="radio" name="trade_volume">
                    > 5,000,000
                  </label>
                  <label class="radio">
                    <input v-model.number="trade_volume" value="4" type="radio" name="trade_volume">
                    > 10,000,000
                  </label>
                  <label class="radio">
                    <input v-model.number="trade_volume" value="5" type="radio" name="trade_volume">
                    > 25,000,000
                  </label>
                  <label class="radio">
                    <input v-model.number="trade_volume" value="6" type="radio" name="trade_volume">
                    > 50,000,000
                  </label>
                </div>
              </div>

              <label class="label">Referrer Discount</label>
              <div class="field has-addons">
                <div class="control">
                  <label class="radio">
                    <input v-model.number="referrer_discount" value="1" type="radio" name="referrer_discount">
                    Yes
                  </label>
                  <label class="radio">
                    <input v-model.number="referrer_discount" value="0" type="radio" name="referrer_discount">
                    No
                  </label>
                  <p class="help">You can have a 5.00% fee discount if you signed up with a referral link</p>
                </div>
              </div>

              <label class="label">FTT Holding (USD)</label>
              <div class="field">
                <div class="control">
                  <select v-model.number="ftt_holding" class="input">
                    <option value="0">
                      $0
                    </option>
                    <option value="3">
                      $100 (3%)
                    </option>
                    <option value="5">
                      $1,000 (5%)
                    </option>
                    <option value="10">
                      $5,000 (10%)
                    </option>
                    <option value="15">
                      $10,000 (15%)
                    </option>
                    <option value="20">
                      $50,000 (20%)
                    </option>
                    <option value="25">
                      $100,000 (25%)
                    </option>
                    <option value="30">
                      $200,000 (30%)
                    </option>
                    <option value="35">
                      $500,000 (35%)
                    </option>
                    <option value="40">
                      $1,000,000 (40%)
                    </option>
                    <option value="45">
                      $2,000,000 (45%)
                    </option>
                    <option value="50">
                      $3,000,000 (50%)
                    </option>
                    <option value="55">
                      $4,000,000 (55%)
                    </option>
                    <option value="60">
                      $5,000,000 (60%)
                    </option>
                  </select>
                  <p class="help">Discount on FTX Trading Fees</p>
                </div>
              </div>

              <label class="label">FTT Staked</label>
              <div class="field">
                <div class="control">
                  <select v-model.number="ftt_staked" class="input">
                    <option value="0">
                      0
                    </option>
                    <option value="1">
                      25 (-0.0000%)
                    </option>
                    <option value="2">
                      150 (-0.0005%)
                    </option>
                    <option value="3">
                      1,000 (-0.0010%)
                    </option>
                    <option value="4">
                      10,000 (-0.0015%)
                    </option>
                    <option value="5">
                      50,000 (-0.0020%)
                    </option>
                    <option value="6">
                      250,000 (-0.0025%)
                    </option>
                    <option value="7">
                      1,000,000 (-0.0030%)
                    </option>
                  </select>
                  <p class="help">FTT stakers receive maker rebates up to 0.0030%, and it costs only 25 FTT to have 0 maker fees</p>
                </div>
              </div>

              <label class="label">Trading Fee</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1">
                  <input
                      v-model.number="fee"
                      class="input"
                      type="text"
                      placeholder="Trading fee result"
                      disabled
                  />
                  <p class="help">Trading fee in cryptocurrencies or fiat currencies</p>
                </div>
              </div>

              <label class="label">Trading Fee %</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1">
                  <input
                      v-model.number="fee_percent"
                      class="input"
                      type="text"
                      placeholder="Trading fee result"
                      disabled
                  />
                  <p class="help">Total Trading Fee % Result</p>
                </div>
                <p class="control">
                  <a class="button is-static">
                    %
                  </a>
                </p>
              </div>
            </div>

            <button
                class="button is-primary"
                @click="calculate()"
            >
              Calculate
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { auth, db } from "@/firebase";

export default {
  name: "AccountCalculatorFTX",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      maker_fee: 0.02,
      taker_fee: 0.07,
      fee: 0,
      fee_percent: 0,
      position_size: 0,
      order_type: 'taker',
      trade_volume: 0,
      referrer_discount: 0,
      ftt_holding: 0,
      ftt_staked: 0
    };
  },
  async created() {
  },
  filters: {
  },
  computed: {
  },
  methods: {
    calculate() {
      if (this.order_type === 'taker') {
        if (this.trade_volume === 1) {
          this.fee_percent = 0.07;
        } else if (this.trade_volume === 2) {
          this.fee_percent = 0.06;
        } else if (this.trade_volume === 3) {
          this.fee_percent = 0.055;
        } else if (this.trade_volume === 4) {
          this.fee_percent = 0.05;
        } else if (this.trade_volume === 5) {
          this.fee_percent = 0.045;
        } else if (this.trade_volume === 6) {
          this.fee_percent = 0.04;
        }
      } else {
        if (this.trade_volume === 1) {
          this.fee_percent = 0.02;
        } else if (this.trade_volume === 2) {
          this.fee_percent = 0.015;
        } else if (this.trade_volume === 3) {
          this.fee_percent = 0.010;
        } else if (this.trade_volume === 4) {
          this.fee_percent = 0.005;
        } else if (this.trade_volume === 5) {
          this.fee_percent = 0;
        } else if (this.trade_volume === 6) {
          this.fee_percent = 0;
        }
      }
      if (this.referrer_discount) {
        this.fee_percent = this.fee_percent * 0.95
      }
      if (this.ftt_holding) {
        this.fee_percent = this.fee_percent * (1 - this.ftt_holding / 100)
      }
      if (this.order_type === 'maker' && this.ftt_staked) {
        if (this.ftt_staked === 1) {
          this.fee_percent = 0;
        } else if (this.ftt_staked === 2) {
          this.fee_percent = -0.0005
        } else if (this.ftt_staked === 3) {
          this.fee_percent = -0.001
        } else if (this.ftt_staked === 4) {
          this.fee_percent = -0.0015
        } else if (this.ftt_staked === 5) {
          this.fee_percent = -0.002
        } else if (this.ftt_staked === 6) {
          this.fee_percent = -0.0025
        } else if (this.ftt_staked === 7) {
          this.fee_percent = -0.003
        }
      }
      this.fee = this.position_size * this.fee_percent / 100
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;
  .card-content {
    width: 100%;
    .title {
      flex: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    transform: scale(1.02, 1.02);

    &::after {
      opacity: 1;
    }
  }
}
.radio:hover, .checkbox:hover {
  color: inherit;
}
</style>
